<template>
    <div>
        <FilterModal
            @reset="resetFilter"
            @filter="getFilterData"
            :isLoading="isLoading"
            class="filterModal"
        >
            <template v-slot:filters>
                <AccountRoleFilter
                    ref="accountRoleFilter"
                    v-model:titleType="filterPayload.titleType"
                    v-model:manCode="filterPayload.manCode"
                    v-model:role="filterPayload.role"
                    v-model:bgCode="filterPayload.bgCode"
                />
                <Textbox
                    name="endDate"
                    type="date"
                    :max="'today'"
                    min="default"
                    :required="true"
                    :title="filterTitles.endDate"
                    v-model="filterPayload.endDate"
                />
                <Dropdown
                    name="dataType"
                    :disabled="true"
                    :title="filterTitles.dataType"
                    :options="filterOptions.dataType"
                    v-model="filterPayload.dataType"
                />
                <Dropdown
                    name="relationMode"
                    :disabled="true"
                    :title="filterTitles.relationMode"
                    :options="filterOptions.relationMode"
                    v-model="filterPayload.relationMode"
                />
            </template>
        </FilterModal>
    </div>
</template>
<script>
import Textbox from '@/components/Textbox.vue'
import Dropdown from '@/components/Dropdown.vue'
import FilterModal from '@/containers/FilterModal.vue'
import AccountRoleFilter from '@/containers/AccountRoleFilter.vue'
import { dailyFilterTitles } from '@/assets/javascripts/report/setting'
import filterDefault from '@/assets/json/filterDefault.json'

export default {
    name: 'DailyFilter',
    emits: [
        'show-data-table',
        'update:filterPayloadProp',
        'update:filterLogProp'
    ],
    components: {
        Textbox,
        Dropdown,
        FilterModal,
        AccountRoleFilter
    },
    props: {
        filterPayloadProp: {
            type: Object,
            default: function () {
                return {
                    manCode: '',
                    endDate: '',
                    bgCode: ''
                }
            }
        },
        filterLogProp: {
            type: Object,
            default: function () {
                return {}
            }
        },
        detail: {
            type: Array,
            default: function () {
                return []
            }
        },
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFilterData: function () {
            /*
             ** 若送api的payload有異動 要重設進階篩選
             ** 若api的payload無異動 只需做進階篩選
             */
            if (
                !this.$isSameValue(
                    this.perviousFilterPayload,
                    this.filterPayload
                )
            ) {
                this.perviousFilterPayload = this.$cloneDeepValue(
                    this.filterPayload
                )
                this.$emit('show-data-table')
            }
            this.filterLog = this.$getFilterLog(this.filterPayload)
        },
        resetFilter: function () {
            this.filterPayload = {
                endDate: this.$formatDate(this.$getDate()),
                ...filterDefault.daily
            }
            // includes mType, manCode(payload), role, bgCode(payload)
            this.$refs.accountRoleFilter.resetFilter()
        }
    },
    computed: {
        filterOptions: function () {
            return {
                relationMode: [
                    {
                        label: '輔導線',
                        value: 'group'
                    }
                ],
                dataType: [
                    {
                        label: '受理',
                        value: '1'
                    }
                ]
            }
        }
    },
    watch: {
        filterPayload: {
            handler() {
                this.$emit('update:filterPayloadProp', this.filterPayload)
            },
            deep: true
        },
        filterLog: {
            handler() {
                this.$emit('update:filterLogProp', this.filterLog)
            },
            deep: true
        }
    },
    data() {
        return {
            allOption: [
                {
                    label: '全部',
                    value: ''
                }
            ],
            filterTitles: {},

            perviousFilterPayload: {},
            filterPayload: this.filterPayloadProp,
            filterLog: this.filterLogProp
        }
    },
    mounted() {
        this.resetFilter()
        this.$nextTick(async () => {
            const accountRoleTitles = this.$refs.accountRoleFilter.filterTitles
            this.filterTitles = {
                ...accountRoleTitles,
                ...dailyFilterTitles,
                _seq: [...accountRoleTitles._seq, ...dailyFilterTitles._seq]
            }
            // this.getFilterData()
        })
    }
}
</script>
