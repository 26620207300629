<template>
    <div>
        <ViewTitle :title="`業績報表 | 當日業績速報表`"> </ViewTitle>
        <DailyFilter
            :isLoading="isLoading"
            :listData="listData"
            :detail="detailData"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <SearchCondition
            :dataList="searchConditionList"
            :isShowTable="true"
            :isHasListData="listData.length > 0"
            :isLoading="isLoading"
        />
        <DailyTable
            :listData="listData"
            :filterLog="filterLog"
            :detail="detailData"
            :isLoading="isLoading"
            :infos="searchConditionList"
        />
    </div>
    <MobileAlertModal v-model="isShowMobileAlert" />
</template>

<script>
import _ from 'lodash'
import ViewTitle from '@/components/ViewTitle.vue'
import SearchCondition from '@/components/SearchCondition.vue'
import MobileAlertModal from '@/containers/report/MobileAlertModal.vue'
import DailyFilter from '@/containers/report/DailyFilter.vue'
import DailyTable from '@/containers/report/DailyTable.vue'
import { getReportListDailyAPI } from '@/assets/javascripts/api'

export default {
    name: 'Daily',
    components: {
        ViewTitle,
        SearchCondition,
        MobileAlertModal,
        DailyFilter,
        DailyTable
    },

    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            try {
                this.isLoading = true
                this.$showLoading()
                // /* Get Table Data */
                let response = await this.$getResponse(
                    this.filterPayload,
                    this.permissionInfo,
                    getReportListDailyAPI
                )
                this.dataProcess(response.data)
                this.listData = response.data.dataSource
            } catch (error) {
                // 後端回 errorCode 為 20001 表示查詢區間查無此人，前端直接當作無業績資料處理
                this.listData = []
            } finally {
                this.isLoading = false
                this.$hideLoading()
            }
        },
        dataProcess: function (data) {
            this.startDate = data?.range?.startDate || '-'
            this.endDate = data?.range?.endDate || '-'
            this.listData = data?.dataSource || []
        }
    },
    computed: {
        detailData: function () {
            const detailArray = []
            this.listData.forEach(function (item, index) {
                item.reportIndex = index + 1
                const detail = _.get(item, 'detail')
                detail.forEach(function (data) {
                    detailArray.push(data)
                })
            })
            return detailArray
        },
        searchConditionList: function () {
            return [
                {
                    title: '查詢月受理日區間',
                    value: `${this.startDate} 至 ${this.endDate}`
                }
            ]
        },
        permissionInfo: function () {
            return {
                apiAlias: 'list',
                apiName: '報表'
            }
        }
    },
    data() {
        return {
            isShowMobileAlert: false,
            isLoading: false,
            filterPayload: {
                titleType: '',
                manCode: '',
                role: '',
                bgCode: '',
                endDate: '',
                relationMode: '',
                dataType: ''
            },
            filterLog: {},
            listData: [],
            startDate: '',
            endDate: ''
        }
    },
    async mounted() {
        this.isShowMobileAlert = this.$isMobile() && !this.$isMobileHorizontal()
    }
}
</script>

<style lang="scss" scoped>
.table-remark {
    min-height: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $secondary-grey;
    font-size: 14px;
    span {
        margin-right: 10px;
    }

    @media screen and (max-width: 576px) {
        flex-wrap: wrap;
        margin-bottom: 15px;
    }
}
</style>
